<template>
  <div>
    <div class="password-label-container">
      <label
        v-if="label"
        for="password"
      >{{ label }}</label>
      <i
        v-tooltip="tooltipData"
        class="fal pointer"
        :class="{'fa-eye': !visiblePassword, 'fa-eye-slash': visiblePassword}"
        @click="visiblePassword = !visiblePassword"
      />
    </div>
    <input
      id="password"
      v-model="passwordValue"
      :type="passwordType"
      name="password"
      class="form-control"
      @focus="$emit('focus')"
      @keyup="$emit('keyup')"
    >
    <PasswordCriteriaIcons
      v-if="showPasswordCriteria"
      :password="passwordValue"
    />
  </div>
</template>
<script>
import PasswordCriteriaIcons from '@/components/security/PasswordCriteriaIcons'

export default {
  name: 'Password',
  components: {
    PasswordCriteriaIcons
  },
  props: ['value', 'label', 'showPasswordCriteria'],
  data () {
    return {
      visiblePassword: false
    }
  },
  computed: {
    passwordValue: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    },
    tooltipData () {
      const text = this.visiblePassword ? this.$t('concealPassword') : this.$t('revealPassword')
      return {
        content: text,
        placement: 'top',
        delay: {
          show: 500,
          hide: 100
        }
      }
    },
    passwordType () {
      return this.visiblePassword ? 'text' : 'password'
    }
  }
}
</script>
<style lang="scss" scoped>
.password-label-container {
  display: flex;
  justify-content: flex-end;
}
</style>
