<template>
  <div class="log-in">
    <h2 class="mb-3">
      {{ $t("signIn") }}
    </h2>
    <form @submit.prevent="onSignIn">
      <div class="form-group">
        <label for="username">{{ $t("username") }}</label>
        <input
          id="username"
          v-model="username"
          v-validate="'required'"
          type="text"
          class="form-control"
          name="username"
          @keyup="removeSSOError"
        >
        <div class="d-flex justify-content-between">
          <div>
            <span
              v-show="errors.has('username')"
              class="error"
            >{{ errors.first('username') }}</span>
            <span
              v-show="ssoError"
              class="error"
            >{{ $t('loginErrorCode1') }}</span>
          </div>
          <div>
            <small
              id="forgotUsername"
              class="form-text"
            >
              <router-link
                :to="{ name: 'ForgotUsername' }"
                tabindex="-1"
              >{{ $t("forgotUsername") }}?</router-link>
            </small>
          </div>
        </div>
      </div>
      <div class="form-group">
        <Password
          v-model="password"
          v-validate="passwordValidation"
          :label="$t('password')"
          name="password"
          data-vv-validate-on="blur"
          @focus="resetPassword"
          @keyup="removePasswordExpired"
        />
        <div class="d-flex justify-content-between">
          <div>
            <span
              v-show="errors.has('password')"
              class="error"
            >{{ errors.first('password') }}</span>
            <span
              v-show="passwordExpired"
              class="error"
            >
              {{ $t("login.passwordExpired") }}
            </span>
          </div>
          <div>
            <small
              id="forgotPassword"
              class="form-text"
            >
              <router-link
                :to="{ name: 'ForgotPassword' }"
                tabindex="-1"
              >{{ $t("forgotPassword") }}?</router-link>
            </small>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button
          type="submit"
          :disabled="errors.any() || submitting || passwordExpired"
          class="btn btn-primary btn-block mt-5"
        >
          {{ $t('signIn') }}
        </button>
      </div>
    </form>
    <p class="help-text">
      {{ $t("areYouNew") }} <router-link :to="{name: 'AccountSetup'}">
        {{ $t("activateAccount") }}.
      </router-link>
    </p>
  </div>
</template>

<script>
import { Helpers } from '@/mixins/Helpers'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import Password from '@/components/security/Password'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import {
  SECURITY_COMMIT_LOGIN_USERNAME,
  SET_LOGIN_SSO_ERROR,
  URL_TO_REDIRECT_AFTER_LOGIN
} from '@/store/modules/security/constants.js'

export default {
  name: 'Login',
  components: { Password },
  mixins: [Helpers, UserManagementComponentMixin],
  data () {
    return {
      username: '',
      password: '',
      submitting: false,
      passwordValidation: 'required',
      passwordExpired: false
    }
  },
  computed: {
    ...mapGetters({
      ssoError: 'getLoginSSOError'
    })
  },
  watch: {
    username (newValue, oldValue) {
      if (newValue.length !== oldValue.length && this.$validator.errors.items.length) {
        this.$validator.reset('username')
      }
    },
    password () {
      if (this.$validator.errors.items.length) {
        this.resetPassword()
      }
    }
  },
  created () {
    this.setLoginUsername('')
    const queryParams = this.$route.query
    const userName = this.$getDecodedId(queryParams.username)
    this.passwordExpired = queryParams.passwordExpired

    if (userName) {
      this.username = userName
    }

    this.overrideSSOError(queryParams.errorCode)
  },
  methods: {
    ...mapActions({
      signIn: 'SIGN_IN'
    }),
    ...mapMutations({
      setLoginUsername: SECURITY_COMMIT_LOGIN_USERNAME,
      setLoginSSOError: SET_LOGIN_SSO_ERROR
    }),
    resetPassword () {
      this.$validator.reset('password')
    },
    overrideSSOError (errorCode) {
      if (errorCode && parseInt(errorCode) === 1) {
        this.setLoginSSOError(true)
        return true
      }
    },
    removeSSOError (evnt) {
      if (evnt.key && evnt.keyCode) {
        this.setLoginSSOError(false)
      }
    },
    onSignIn () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          localStorage.setItem('login_username', this.$getEncodedId(this.username))
          const loader = this.$loading.show()
          this.submitting = true
          const { username, password } = this
          this.signIn({ username, password })
            .then((response) => {
              return this.handleLoginResponseCallback(response)
            }).catch((e) => {
              return this.$handleApiError(e)
            }).finally(() => {
              loader.hide()
              this.submitting = false
            })
        }
      })
    },
    handleLoginResponseCallback (response) {
      if (response && response.status !== 200) {
        return this.$validator.errors.add('password', this.getErrorString(response.data))
      }
      if (response && response.data && response.data.force_reset_password) {
        return this.$router.push({ name: 'ResetPassword' })
      }
      this.setLoginSSOError(false)
      this.setLoginUsername(this.username)
      this.$genericDispatch('GENERAL_SETTINGS')
      this.$genericDispatch('GET_UI_SETTINGS').then(() => {
        this.redirectUser()
      })
    },
    redirectUser () {
      return this.redirectAccessAttempt('Home')
    },
    redirectAccessAttempt (homepage) {
      const accessAttempt = JSON.parse(localStorage.getItem(URL_TO_REDIRECT_AFTER_LOGIN))
      const allowedRouteForUser = this.allowedRouteForUser(accessAttempt)
      if (!accessAttempt || !accessAttempt.name || !allowedRouteForUser) {
        return this.$router.push({ name: homepage })
      }

      localStorage.removeItem(URL_TO_REDIRECT_AFTER_LOGIN)
      return this.$router.push({ name: accessAttempt.name, params: accessAttempt.params })
    },
    openLegacyApp () {
      return window.open(`${window.location.origin}/v1`, '_self')
    },
    allowedRouteForUser (accessAttempt) {
      if (!accessAttempt) {
        return false
      }

      if (accessAttempt && !accessAttempt.meta) {
        return true
      }

      if (accessAttempt && accessAttempt.meta && !accessAttempt.meta.permissions) {
        return true
      }

      return this.userHasAccessToRoute(accessAttempt.meta.permissions)
    },
    getErrorString (responseData) {
      if (responseData.errors) {
        const errorsArray = Object.values(responseData.errors)
        if (errorsArray.length) {
          const errors = errorsArray.join(', ')
          return `${errors}`.trim()
        }
      }
      return responseData.error ? responseData.error : null
    },
    removePasswordExpired () {
      this.passwordExpired = false
    }
  }
}
</script>
