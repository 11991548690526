<template>
  <div class="password-criteria-icons">
    <span
      v-for="(criteria, index) in criterias"
      :key="index"
    >
      <i
        class=""
        :class="validate(criteria.regex) ? 'fas fa-check-circle' : 'fal fa-circle'"
      /> <span v-html="criteria.label" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'PasswordCriteriaIcons',
  props: ['password'], // eslint-disable-line vue/require-prop-types
  data () {
    return {
      criterias: [
        {
          label: '<b>12</b>+chars',
          regex: /^.{12,}$/
        },
        {
          label: 'ABC',
          regex: /[A-Z]+/
        },
        {
          label: 'abc',
          regex: /[a-z]+/
        },
        {
          label: '123',
          regex: /[0-9]+/
        },
        {
          label: '!@#',
          regex: /[()[\]{}|\\`~!@#$%^&*_\-+=;:'",<>./?]+/
        }
      ]
    }
  },
  methods: {
    validate (regex) {
      return regex.test(this.password)
    }
  }
}
</script>
